<template>

    <div class="myRepair">
        <rxNavBar title="我的维修" androidOrIOSFlag="true"></rxNavBar>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <!-- 轮播图开始-->
        <div class="grayDiv" >
            <!--轮播图 autoplay:自动播放时间 bannerList:轮播数组 hideDialog:跳转富文本或者其他页面方法  height:滑块高度 indicators:是否有指示器-->
            <dial-swiper :autoplay="autoplay" :bannerList="bannerList" :indicators="indicators" :height="height" @swiperJump="swiperJump(arguments)" >
            </dial-swiper>
        </div>

        <!-- 轮播图结束-->

        <div class="manualRepairDiv" @click="showMobile(repairMobile)">
            <div class="manualRepair">人工报修</div>
            <div class="repairStyle">直通电话，快速保修</div>
        </div>
        <div class="onlineRepairDiv" @click="iWantRepair">
            <div class="onlineRepair">线上报修</div>
            <div class="repairStyle">自动报修问题，上门维修</div>
        </div>
        <div class="img_group">
            <img class="head-quotation-mark" src="../../../assets/images/head-quotation-mark.png">
            <div class="repair">付费维修</div>
        </div>
        <!--维修列表-->
        <div class="repairList_top">
            <div class="repairType" v-for="(items,index) in repairType" :key="index" @click="iWantRepairs(items.dictionaryTitle,items.id)">
                <div class="repairList_text">
                    <div class="brandband"><img :src="items.dictionaryDescription==undefined && items.dictionaryDescription==''? errorImgA:items.dictionaryDescription" class="brandbandImage"></div>
                    <div class="brandbandTitle">{{items.dictionaryTitle}}</div>
                </div>
                <div class="line"></div>
            </div>
        </div>
        <div class="tip">*部分维修为收费项目</div>
        <dial-mobile :ownerMobile="temporaryOwnerMobile" :userName="temporaryOwnerName" :dialogTitle="dialogTitle" :mobileShow="isMobileShow"  @hideDialog="hideDialog">
        </dial-mobile>
        <div class="img_group">
            <img class="head-quotation-mark" src="../../../assets/images/head-quotation-mark.png">
            <div class="repair">我的报修</div>
        </div>

        <div class="reviews" v-if="noContent"> <common-empty ></common-empty></div>
        <div class="reviews" v-else>

            <div class="review" v-for="(item,index) in repairHitoryList" :key="index">
                <div :class="item.checkStatusStr=='派单中'?'top-title-green':item.checkStatusStr=='待审批'||item.checkStatusStr=='待处理'?'top-title-orange':item.checkStatusStr=='已验收'||item.checkStatusStr=='已完成'||item.checkStatusStr=='已取消'?'top-title-blue':'top-title-orange'">
                    {{item.checkStatusStr}}
                </div>
                <div class="positionDiv">
                    <div class="position">
                        <span class="position-span-right">{{item.roomAddress}}</span>
                    </div>
                </div>

<!--                <div class="lineDiv"></div>-->
                <div class="reject">
                    <div class="text">预约时间：<span class="sp">{{item.appointmentTime}}</span></div>
                    <div class="text">维修类型：<span class="sp">{{item.repairTypeStr}}</span></div>
                    <div class="text">维修描述：<span class="sp">{{item.afterRentDesprition}}</span></div>
                    <div class="text" v-if="item.checkStatusStr=='已取消'">取消原因：<span class="sp">{{item.cancelReason}}</span></div>
                    <div class="text" v-if="(item.costBearStr!=''&&item.costBearStr!=undefined)&& item.checkStatusStr!='派单中'">{{item.costBearStr}}：<span class="cost">{{item.fee==''||item.fee==undefined?'0.00':item.fee}}元</span></div>
                    <div class="text" v-if="(item.costBearStr!=''&&item.costBearStr!=undefined) &&item.checkStatusStr=='派单中'">{{item.costBearStr}}：<span class="cost">{{item.expectFee==''||item.expectFee==undefined?'0.00':item.expectFee}}元</span></div>

                </div>
                <div class="finishDiv" :class="item.checkStatusStr!='已验收' ? 'trashCanHide':''">
                    <div class="finish"  >
                        <!--                    <div class="lineDiv"></div>-->
                        <div class="text">完成时间：<span class="sp">{{item.finishTime}}</span></div>
                        <div class="text">完成描述：<span class="sp">{{item.checkDescription}}</span></div>
                    </div>

                </div>

                <div :class="item.number != 1 ? 'trashCanHide':''" class="lineDivBottom"></div>
                <div>
                    <div class="bottom">
                        <div :class="item.checkStatusStr=='待审批'||item.checkStatusStr=='待处理' ? 'trashCanHide':''" style="" class="bottom_div">
                            <div class="maintenanceWorker"></div>
                            <div class="repairMan">{{item.repairMan}}</div>
                            <div class="mobile">{{item.mobile}}</div>
                            <div class="tel_IMg" :class="item.checkStatusStr !='派单中' ? 'trashCanHide':''" @click="showMobile(item.mobile)">
                                <img class="tel" src="../../../assets/images/tel.png">
                            </div>
                        </div>

                        <!--                取消维修按钮-->
                        <div class="cance">
                            <button @click="cancelClick(item.afterRent_id)" :class="item.checkStatusStr!='待审批'&&item.checkStatusStr!='待处理'&&item.checkStatusStr!='派单中'?'trashCanHide':''" class="cancelBtn">取消维修</button>
                        </div>

                    </div>
                </div>
            </div>
            <!--        取消弹框-->
            <van-popup v-model="cancelShow" class="van-popup--bottom" position="bottom" :style="{ height: '45%' }" >
                <img style="float: left;margin: 20px 5px 0 15px;width: 15px;height: 15px;" src="../../../assets/images/colon.png">
                <div style="margin-top: 18px;font-size: 15px;font-weight: bold" >确认</div>
                <div style="margin-left: 35px;margin-bottom: 15px;font-size: 15px;font-weight: bold">取消该维修单？</div>
                <textarea placeholder="输入取消原因" v-model="cancelReason"  @input="handleInput"/>
                <van-button @click="cancelBtn" class="saveButton_Enable">确认</van-button>
            </van-popup>
        </div>
        </van-pull-refresh>
    </div>

</template>

<script>
    import {userRepairInit,getQueryBaseData,userAfterRentCancel} from "../../../getData/getData";
    import { globaluserId,checkAndroid, checkIOS, responseUtil} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {
        Button,
        DatetimePicker,
        DropdownItem,
        DropdownMenu,
        NavBar,
        Overlay,
        Popup,
        Rate,
        Search,
        Uploader,
        Swipe,
        List,
        SwipeItem,
        PullRefresh
    } from "vant";
    Vue.use(Swipe,SwipeItem,List,PullRefresh);
    import Vue from 'vue';
    import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'
    import dialSwiper from '../../../components/rongxun/rx-swiper/dialSwiper'
    //
    /********调用APP方法 begin*********/
    function codeJump(name){
        window.himi.gotoPage(name);
    }
    /********调用APP方法 end*********/

    export default {
        components: {
            [NavBar .name]:NavBar,
            [Search .name]:Search,
            [DropdownMenu .name]:DropdownMenu,
            [DropdownItem .name]:DropdownItem,
            [Popup.name]: Popup,
            [Button.name]: Button,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [Rate .name]: Rate ,
            [Overlay .name]: Overlay ,
            [List.name]:List,
            [PullRefresh.name]:PullRefresh,
            [Swipe.name]:Swipe,
            [SwipeItem.name]:SwipeItem,
            dialMobile,
            rxNavBar,
            dialSwiper
        },
        name: "myRepair",
        data(){
            return{
                cancelShow:false,
                //拨打电话弹窗 start
                isMobileShow:false,
                temporaryOwnerMobile:'',
                temporaryOwnerName:'',
                dialogTitle:'联系维修师傅',
                //拨打电话弹窗 end
                repairMobile:'',
                payRepairList:'',
                freeRepairList:'',
                repairHitoryList:'',
                fdName:["CHECKSTATUSMAP","COSBEARMAP","AFTERRENTSTATUSMAP"],
                dbName:["repairType"],
                repairType:[],
                checksTatusmap:[],
                orderList:[],
                cosbearMap:[],
                bannerList:[],//轮播列表
                isLoading: false,
                changeType:'1',
                noContent:false,
                errorImgA: 'https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png',
                errorImg:[{"bannerPath":"https://himihome.oss-cn-qingdao.aliyuncs.com/images/platform/templatetmp/2589de77-4277-4be7-add9-f09f2b176e57.png"}],//默认图片
                //所有状态列表
                infoList:[],
                type:'1',
                afterRent_id:'',//租后id
                cancelReason:'',//取消原因
                autoplay:"2000",//轮播参数
                height: "140",//滑块高度
                indicators:true,//是否需要指示器
                msg:"要跳转的页面"
            }
        },
        mounted() {
            this.userRepairInit();
            this.getQueryBaseData();
        },

        methods:{
            //
            iWantRepair() {
                this.$router.push({
                    name: 'contractChoose',
                    query: {
                        changeType:this.changeType,//页面判断跳转我要维修页面
                    },
                })
            },
            iWantRepairs(dictionaryTitle,id) {
                this.$router.push({
                    name: 'contractChoose',
                    query: {
                        changeType:this.changeType,//页面判断跳转我要维修页面
                        dictionaryTitle:dictionaryTitle,//维修类型
                        id:id
                    },
                })
            },
            //返回上一页
            leftReturn() {
                this.$router.go(-1);
            },
            // 弹框按钮--点击触发弹框--确认后弹框消失
            cancelClick(id){
                this.cancelShow = true
                this.afterRent_id=id//获取租后id
            },
            cancelBtn(){
                this.cancelShow = false
                this.userAfterRentCancel();//取消接口
                this.onRefresh();//刷新页面
            },
            handleInput(e){
                // this.cancelReason=e.data//取消原因
            },
            onRefresh() {//刷新
                setTimeout(() => {
                   this.repairType=[];
                   this.checksTatusmap=[];
                   this.orderList=[];
                   this.cosbearMap=[];
                   this. bannerList=[];
                   this.isLoading= false,
                   this.userRepairInit();
                   this.getQueryBaseData()
                },800);
            },
            //拨打电话
            showMobile(e){
                let that = this;
                that.temporaryOwnerMobile = e;
                that.dialogTitle='人工报修',
                that.isMobileShow = !that.isMobileShow;
            },
            //关闭拨打电话弹窗
            hideDialog() {
                let that = this;
                that.isMobileShow = false;
            },
            userRepairInit(){ //维修项目初始化接口
                let that = this;
                let userRepairInitData={
                    user_id:globaluserId(),
                }
                userRepairInit(userRepairInitData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response)
                        if (response.data.code == 0){
                            that.repairMobile=response.data.data.repairMobile,//维修电话
                            that.freeRepairList=response.data.data.freeRepairList,//免费列表
                            that.payRepairList= response.data.data.payRepairList//付费项目

                            if (response.data.data.repairHitoryList.length==0){
                                that.noContent=true
                            } else{

                                that.repairHitoryList=response.data.data.repairHitoryList;//维修历史列表
                            }
                            if(response.data.data.repairBannerList==undefined||response.data.data.repairBannerList==''){//没有图片时添加默认图片
                                that.bannerList=that.errorImg
                            }else{
                                that.bannerList=response.data.data.repairBannerList //轮播列表
                            }

                        }else if (response.data.code != 0) {
                            responseUtil.alertMsg(that,response.data.msg)
                        } else {
                            responseUtil.alertMsg(that,response.data.msg)
                        }
                    })
                })
            },

            getQueryBaseData(){//字典表接口
                let that =this;
                let _parms = {
                    fdName:that.fdName,
                    dbName:that.dbName,
                }
                getQueryBaseData(_parms).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                    if(response.data.code==0){
                        that.checksTatusmap=response.data.data.AFTERRENTSTATUSMAP,
                        that.repairType=response.data.data.repairType,
                        that.cosbearMap=response.data.data.COSBEARMAP
                    }else if (response.data.code != 0){
                        responseUtil.alertMsg(that,response.data.msg)
                    }else{
                        responseUtil.alertMsg(that,response.data.msg)
                    }
                })
            })
        },

            userAfterRentCancel(){//用户租后取消申请接口
                let that =this;
                let _parms = {
                    user_id:globaluserId(),
                    afterRent_id:that.afterRent_id,
                    type:that.type,
                    cancelReason:that.cancelReason
                }
                userAfterRentCancel(_parms).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code==0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else if (response.data.code != 0){
                            responseUtil.alertMsg(that,response.data.msg)
                        }else{
                            responseUtil.alertMsg(that,response.data.msg)
                        }
                    })
                })
            },

            swiperJump(item) {
                //取出点击数据
                var list=item[0];
                var index=item[1]
                console.log(list)
                console.log(index)
                if (!list){
                    return
                } else{
                    if (list.bannerJumpType==0){
                        //跳转页面
                        if (list.dictionaryName==("roomList"||"mine"||"workMine"||"workHome"||"room"||"workData")) {
                            this.numLogin(list.dictionaryName)
                        }
                    }else if(list.bannerJumpType==1){
                        this.jumprichText(list.richText)
                    }else{
                        return;
                    }
                }
            },

            numLogin(name) {
                if (checkAndroid()) {
                    codeJump(name);
                } else if (checkIOS()) {
                    console.log("对ios传参");
                    window.webkit.messageHandlers.gotoPage.postMessage({body:name});
                }
            },
            //跳转原生页面
            jumprichPage(bannerJump){
                this.$router.push({
                    name : bannerJump,
                    query : {
                        textType : bannerJump,
                        type : '2',
                    }
                })
            },

            //跳转富文本页面
            jumprichText(type){
                this.$router.push({
                    name : 'userService',
                    query : {
                        textType : type,
                        type : '1',
                    }
                })
            },
        }
    }
</script>

<style lang="less" scoped>
    .myRepair{
        padding-bottom: 100px;
    }
    .navbar{
        z-index: 10;
        background-color: #f8f8f8;
    }
    .grayDiv{
        width: 345px;
        height: 140px;
        border-radius: 8px;
        margin:0px 0 22px 15px;
    }
    .grayDiv_image{
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }
    .brandbandTitle{
        font-size:12px;
        margin-top: 8px;
    }
    //轮播图属性
    .my-swipe{
        width: 345px;
        height: 140px;
        border-radius: 8px;
    }
    .bottom_div{
        /*float: left;*/
        /*height: 20px;*/
       width: 100%;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top: 1px solid #f8f8f8;
        justify-content: left;
        /*margin: 10px 0px;*/
        padding: 10px 0px;


        /*margin: 20px 0px*/
    }
    .manualRepairDiv{
        float: left;
        width: 165px;
        height: 71px;
        background:rgba(255, 255, 240) url("../../../assets/images/manualRepair.png")no-repeat 100% 100%;
        /*background-color:#f7e38282;*/
        /*background-color: #ded387ad;*/
        margin: 0 15px 15px 15px;
        border-radius: 8px;
        box-shadow:0px 15px 15px -15px #ccc;
    }
    .onlineRepairDiv{
        width: 165px;
        height: 71px;
        background:rgb(255, 225, 225) url("../../../assets/images/onlineRepair.png")no-repeat 100% 100%;
        /*background-color: rgb(255, 225, 225);*/
        float: left;
        margin-bottom: 15px;
        border-radius: 8px;
        box-shadow:0px 15px 15px -15px #ccc;
        border-bottom: 0.5px solid pink;
    }
    .lineDiv{
        float: left;
        width: 100%;
        border:0.5px solid #f8f8f8;
        margin:2px 0px 5px 0px;
    }
    .lineDivBottom{
        float: left;
        width: 100%;
        border:0.5px solid #f8f8f8;
        margin-top:10px;
    }
    .manualRepair{
        font-size: 15px;
        margin-top: 15px;
        margin-left: 10px;
        font-weight: bold;
    }
    .onlineRepair{
        font-size: 15px;
        margin-top: 15px;
        margin-left: 10px;
        font-weight: bold;
    }
    .repairStyle{
        color:#999999;
        font-size: 9px;
        margin-left: -5px;
        line-height: 15px;
        transform: scale(0.8);
    }
    .img_group{
        display: flex;
        width: 200px;
        height: 70px;
        /*margin-top: 30px;*/
    }
    .head-quotation-mark{
        height: 60px;
    }
    .repair{
        width: 120px;
        height: 20px;
        color: rgba(34, 34, 34, 1);
        font-size: 20px;
        text-align: left;
        font-family: PingFangSC-Semibold;
        margin-left: -28px;
        margin-top: 30px;
        /*position: relative;*/
        font-weight: bold;
        left: 24px;
        bottom: 30px;
    }
    .repairList_top{
        /* position: absolute;*/
        display: flex;
        /* float: left;*/
        width: 345px;
        /*height: 90px;*/
        flex-direction: row;
        align-content: center;
        justify-content: left;
        height: auto;
        flex-wrap: wrap;
        /*white-space: normal;*/
        background-color: white;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        margin-left: 15px;
    }
    .repairType{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px 0px;
    }
    .repairList_text{
        margin-left: 20px;
    }
    .repairList_bottom{
        /* position: absolute;*/
        display: flex;
        /* float: left;*/
        width: 345px;
        height: 90px;
        background-color: white;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        margin-left: 15px;
    }
    .brandband{
        width: 35px;
        height: 35px;
        /*margin-left: 5px;*/
        /*background: url("../../../assets/images/brandband.png")no-repeat 100% 100%;*/
        margin-left: 5px;
        /*margin: 20px 25px 10px 20px;*/
    }
    .brandbandImage{
        width: 100%;
        height: 100%;
    }
    .door{
        width: 35px;
        height: 35px;
        /*margin-left: 5px;*/
        background: url("../../../assets/images/door.png")no-repeat 100% 100%;
        margin-left: 5px;
    }
    .line{
        height: 45px;
        margin-left: 15px ;
        border: 0.5px solid #EFEFEF;
    }
    .air{
        width: 35px;
        height: 35px;
        background: url("../../../assets/images/air.png")no-repeat 100% 100%;
        margin-left: 5px;
        /*margin: 20px 25px 10px 25px;*/
    }
    /*家具维修样式*/
    .furniture{
        width: 35px;
        height: 35px;
        background: url("../../../assets/images/furniture.png")no-repeat 100% 100%;
        margin-left: 5px;
        /*margin: 20px 25px 10px 25px;*/
    }
    .homeAppliance{
        width: 33px;
        height: 33px;
        background: url("../../../assets/images/homeAppliance.png")no-repeat 100% 100%;
        margin-left: 5px;
        /*margin: 20px 25px 10px 25px;*/
    }
    /*疏通管道样式*/
    .dredge{
        width: 33px;
        height: 33px;
        background: url("../../../assets/images/dredge.png")no-repeat 100% 100%;
        margin-left: 5px;
        /*margin: 20px 25px 10px 25px;*/
    }
    .light{
        width: 35px;
        height: 35px;
        background: url("../../../assets/images/light.png")no-repeat 100% 100%;
        margin-left: 5px;
        /*margin: 20px 0 10px 25px;*/
    }
    .lock{
        width: 35px;
        height: 35px;
        background: url("../../../assets/images/lock.png")no-repeat 100% 100%;
        margin-left: 5px;
    }
    .tip{
        color: #999999;
        width: 345px;
        height: 17px;
        font-size: 12px;
        text-align: right;
        float: right;
        margin-right: 15px;
        margin-top: 6px;
    }
    .reviews{
        float: left;
        /*margin-top: 17px;*/
        width: 10rem;
        /*height: 285px;*/
    }
    .review{
        margin: 0 15px 15px 15px;
        width: 345px;
        /*height: 295px;*/
        overflow: hidden;
        background-color: white;
        border-radius: 8px;
    }
    .positionDiv{
        border-bottom: 1px solid #f8f8f8;
        /*padding: 5px 0px;*/
    }
    .position{
        margin: 5px 10px 0 15px;
        height: 42px;
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        width: 290px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

    }
    .position-span-right{
        color: black;
        font-size: 15px;
    }
    .sp{
        color: #9a9a9a;
        font-size: 14px;
        font-weight: normal;
    }
    .cost{
        color: red;
        font-size: 14px;
        font-weight: normal;
    }
    .text{
        color: black;
        font-size: 14px;
        font-weight: bold;
    }
    .reject{
        width: 315px;
        /*height: 110px;*/
        padding: 10px 0px;
        margin-left: 15px;
    }
    .finishDiv{
    padding: 10px 0px;
        border-top: 1px solid #f8f8f8;
    }
    .finish{
        width: 345px;
        /*height: 48px;*/
        margin-left: 15px;

        /*border-bottom: 0.5px solid ;*/
    }
    .top-title-green{
        /*margin-bottom: 4px;*/
        width: 43px;
        height: 16px;
        font-size: 10px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-family: PingFangSC-Semibold;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: linear-gradient(to right,#63cb96,#30d0ac);
        color: white;
        /*transform:scale(0.8);*/
    }
    .top-title-orange{
        /*margin-bottom: 4px;*/
        width: 43px;
        height: 16px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 10px;
        font-family: PingFangSC-Semibold;
        border-top-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        /*transform:scale(0.8);*/
    }
    .top-title-blue{
        width: 43px;
        height: 16px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-size: 10px;
        font-family: PingFangSC-Semibold;
        /*border-radius: 6px 0;*/
        border-top-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: linear-gradient(to right,#9d9d9d,#9a9a9a);
        color: white;
        /*transform:scale(0.8);*/
    }
    .bottom{
        width: 345px;
        /*border-top: 1px solid #f8f8f8;*/
        /*height: 60px;*/
        /*margin-left: 15px;*/
    }
    .tel_IMg{
        /*float: right;*/
        width: 20px;
        height: 20px;
        margin-left: 75px;


    }
    /*弹窗样式*/
    .van-popup--bottom{
        /*z-index: 2004;*/
        background-color: #f8f8f8;
    }
    .saveButton_Enable{
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin:40px 15px 15px 15px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;
    }
    textarea{
        resize: none;
        color: gray;
        font-size: 12px;
        width: 330px;
        height: 80px;
        background: #f0f0f0;
        margin-left: 15px;
        border-radius: 10px;
        padding: 10px 0 0 10px;
        border: none;
    }
    .tel{
        width: 100%;
        height: 100%;

        /*width: 19px;*/
        /*height: 18px;*/
    }
    .maintenanceWorker{
        /*float: left;*/
        /*margin-top: 5px;*/
        /*margin-left: 15px;*/
        width: 20px;
        height: 20px;
        margin-left: 15px;
        background: url("../../../assets/images/maintenanceWorker.png") no-repeat 100% 100%;
    }
    .repairMan{
        margin-left: 10px;
        width: 50px;
    }
    .mobile{
        margin-left: 10px;
        width: 120px;
        color: #9a9a9a;
    }
    .trashCanHide{
        display: none;
    }
    .trashCanShow{
        display: block;
    }
    .cance{
        border-top: 1px solid #f8f8f8;
    }
    .cancelBtn{
        float: right;
        height: 30px;
        width: 80px;
        margin: 10px 0px;
        /*margin-top: 30px;*/
        font-size: 12px;
        /*line-height: 31px;*/
        margin-right: 15px;
        border: 1px solid transparent;
        border-radius: 10px;

        background-image: linear-gradient(white,white),
        linear-gradient(to right,#ffc274,#ff5D3B);
        background-clip: padding-box ,border-box;
        background-origin: border-box;
        color: #ff5809;
        text-align: center;
    }
</style>
